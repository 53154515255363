<div class="footer p-0-md-sm position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md col-6 links">
        <h1>{{ 'ABOUT_US' | translate }}</h1>
        <ul class="list-unstyled m-0 d-flex flex-column justify-content-center">
          <li>
            <a [attr.href]="clientUrl + '/about'" target="_blank">{{ 'MEET_THE_TEAM' | translate }}</a>
          </li>
          <li>
            <a [attr.href]="clientUrl + '/explore-tutors'" target="_blank">{{ 'OUR_STAR_TUTORS' | translate }}</a>
          </li>
          <li>
            <a [attr.href]="clientUrl + '/blogs'" target="_blank">{{ 'BLOGS' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md col-6 links">
        <h1>{{ 'LEARN' | translate }}</h1>
        <ul class="list-unstyled m-0 d-flex flex-column justify-content-center">
          <li>
            <a [attr.href]="clientUrl + '/all-courses'" target="_blank">{{ 'COURSES' | translate }}</a>
          </li>
          <li>
            <a [attr.href]="clientUrl + '/prices'" target="_blank">{{ 'PRICES' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md col-6 links">
        <h1>{{ 'SIGN_UP' | translate }}</h1>
        <ul class="list-unstyled m-0 d-flex flex-column justify-content-center">
          <li>
            <a [attr.href]="clientUrl + '/signup?role=1'" target="_blank">{{ 'START_LEARNING' | translate }}</a>
          </li>
          <li>
            <a [attr.href]="clientUrl + '/signup?role=3'" target="_blank">{{ 'START_TEACHING' | translate }}</a>
          </li>
          <li>
            <a [attr.href]="clientUrl + '/edumalls'">{{ 'REDEEM_EDUMALLS_COUPON' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md col-6 links">
        <h1>{{ 'HELP' | translate }}</h1>
        <ul class="list-unstyled m-0 d-flex flex-column justify-content-center">
          <li>
            <a [attr.href]="clientUrl + '/faq'" target="_blank">{{ 'FAQS' | translate }}</a>
          </li>
          <li>
            <a [attr.href]="clientUrl + '/contact'" target="_blank">{{ 'CONTACT_US' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="divider mt-30 mt-15-sm"></div>
    <div class="row subscribe mt-15-sm p-10-sm">
      <div class="col-md-4 p-0-sm">
        <h1 class="p-0-sm">{{ 'KEEP_UPDATED' | translate }}</h1>
        <p class="p-0-sm">{{ 'KEEP_UPDATED_DESC' | translate }}</p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-block position-relative">
            <input type="email" formControlName="email" placeholder="{{ 'ENTER_UR_EMAIL' | translate }}..." class="w-100" />
            <button mat-flat-button color="primary" type="submit" [disabled]="(isSignupNewsLetter$ | async)!">
              <mat-icon class="spinner me-2" *ngIf="(isSignupNewsLetter$ | async)!">autorenew</mat-icon>
              {{ 'SUBSCRIBE' | translate }}
            </button>
          </div>
          <div *ngIf="email?.invalid && email?.touched">
            <small class="text-danger">{{ 'WRITE_VALID_EMAIL' | translate }}</small>
          </div>
        </form>
      </div>
      <div class="col-md-4 offset-md-1 p-0-sm">
        <h1 class="p-0-sm mt-15-sm">{{ 'JOIN_US' | translate }}</h1>
        <p class="p-0-sm">{{ 'JOIN_US_DESC' | translate }}</p>
        <a [attr.href]="clientUrl + '/contact'" target="_blank">{{ 'SAY_HELLO' | translate }}</a>
      </div>
      <div class="col-lg-2 col-md-3 offset-lg-1 social p-0-sm mt-15-sm">
        <h1 class="p-0-sm">{{ 'SOCIAL' | translate }}</h1>
        <div class="d-flex">
          <a mat-button href="https://www.facebook.com/metutorsco/" target="_blank">
            <svg-icon name="facebook" [applyClass]="true" class="social-link"></svg-icon>
          </a>
          <a mat-button href="https://twitter.com/MEtutors247" target="_blank">
            <svg-icon name="x" [applyClass]="true" class="social-link"></svg-icon>
          </a>
          <a mat-button href="https://www.instagram.com/metutors" target="_blank">
            <svg-icon name="instagram" [applyClass]="true" class="social-link"></svg-icon>
          </a>
          <a mat-button href="https://www.linkedin.com/company/metutors" target="_blank">
            <svg-icon name="linkedin-circle" [applyClass]="true" class="social-link"></svg-icon>
          </a>
          <a mat-button href="https://www.reddit.com/user/MEtutors" target="_blank">
            <svg-icon name="reddit" [applyClass]="true" class="social-link"></svg-icon>
          </a>
          <a mat-button href="https://www.youtube.com/channel/UC2f8Y6Hhc2qTsjISnjlrmiQ" target="_blank">
            <svg-icon name="youtube" [applyClass]="true" class="social-link"></svg-icon>
          </a>
          <!-- <a mat-button href="https://www.youtube.com/channel/UC2f8Y6Hhc2qTsjISnjlrmiQ" target="_blank">
            <img src="assets/svg/tiktok.svg" alt="" draggable="false" />
          </a> -->
        </div>

        <img src="assets/courses/qr.jpeg" width="60px" height="60px" alt="" class="mt-3 d-block">
      </div>
    </div>
    <div class="divider"></div>
    <div class="row copyright">
      <div class="col-lg-9 d-flex justify-content-between d-block-sm">
        <div class="d-flex align-items-center justify-content-center">
          <img src="assets/svg/mastercard.svg" width="50" height="30" alt="mastercard" class="me-3">
          <img src="assets/svg/visa.svg" width="60" height="20" alt="visa" class="me-3">
          <img src="assets/svg/paypal.svg" width="70" height="40" alt="visa" class="me-3">
          <img src="assets/svg/payoneer.svg" width="100" height="40" alt="visa" class="me-3">
        </div>
        <ul class="list-inline m-0 p-0 mt-15-sm d-flex align-items-center flex-wrap">
          <li class="list-inline-item">
            <a [attr.href]="clientUrl + '/privacy-notice'" target="_blank">{{ 'PRIVACY_NOTICE' | translate }}</a>
          </li>
          <li class="list-inline-item">
            <a [attr.href]="clientUrl + '/cookie-notice'" target="_blank">{{ 'COOKIES_NOTICE' | translate }}</a>
          </li>
          <li class="list-inline-item">
            <a [attr.href]="clientUrl + '/website-terms'" target="_blank">{{ 'WEBSITE_TERMS' | translate }}</a>
          </li>
          <li class="list-inline-item">
            <a [attr.href]="clientUrl + '/student-terms'" target="_blank">{{ 'STUDENT_TERMS' | translate }}</a>
          </li>
          <li class="list-inline-item">
            <a [attr.href]="clientUrl + '/tutor-terms'" target="_blank">{{ 'TUTOR_TERMS' | translate }}</a>
          </li>
          <li class="list-inline-item">
            <a [attr.href]="clientUrl + '/community-terms'" target="_blank">{{ 'COMMUNITY_TERMS' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 text-end mt-15-sm d-flex align-items-center justify-content-end justify-content-center-md-sm mt-15-md-sm">
        <bdi> © {{ myDate | date: "y" }} {{ 'METUTORS' | translate }}. {{ 'ALL_RIGHTS_RESERVED' | translate }}</bdi>
      </div>
    </div>
  </div>
</div>
