import { createAction, props } from '@ngrx/store';

export const changeCurrency = createAction(
  '[Currency Menu] Change Currency',
  props<{ currency: any }>()
);

export const changeCurrencySuccess = createAction(
  '[Currency Menu] Change Currency Success'
);

export const changeCurrencyFailure = createAction(
  '[Currency Menu] Change Currency Failure',
  props<{ error: string }>()
);

export const changeLanguage = createAction(
  '[Language Menu] Change Language',
  props<{ language: string }>()
);

export const changeLanguageSuccess = createAction(
  '[Language Menu] Change Language Success'
);

export const changeLanguageFailure = createAction(
  '[Language Menu] Change Language Failure',
  props<{ error: string }>()
);
