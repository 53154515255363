import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { environment } from '@environment';
import { DOCUMENT } from '@angular/common';
import * as fromCore from '@metutors/core/state';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ICountry, ISubscription, IUser } from './core/models';
import { filter, map, mergeMap, Observable, Subscription } from 'rxjs';
import { Inject, OnInit, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
  NavigationError,
  NavigationCancel,
} from '@angular/router';

@Component({
  selector: 'metutors-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('progressBar', { static: true }) progressBar: any;

  returnUrl$: Observable<string>;
  user$: Observable<IUser | null>;
  paddingTop$: Observable<number>;
  openSignInModal$: Observable<boolean>;
  signInSubHeading$: Observable<string>;
  loadingCountries$: Observable<boolean>;
  countries$: Observable<ICountry[] | null>;
  subscription$: Observable<ISubscription | null>;

  url: string;
  layout?: any;
  routerSubscription$: Subscription;
  maintenanceMode = environment.maintenanceMode;

  constructor(
    private _title: Title,
    private _router: Router,
    private _store: Store<any>,
    private _route: ActivatedRoute,
    public translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if (typeof window !== 'undefined') {
      translate.addLangs(['ar', 'en']);
      translate.setDefaultLang('ar');

      const defaultLanguage = localStorage.getItem('DEFAULT_LANGUAGE');

      if (!defaultLanguage)
        localStorage.setItem('DEFAULT_LANGUAGE', translate.currentLang || 'ar');

      translate.use(
        defaultLanguage?.match(/en|ar/)
          ? defaultLanguage
          : translate.currentLang || 'ar'
      );
      this._checkLangauge();

      translate.onLangChange.subscribe(() => this._checkLangauge());
    }

    this.routerSubscription$ = this._router.events.subscribe((event: any) => {
      this.url = this._router.url?.split('?')[0]?.split('#')[0];
      this._navigationInterceptor(event);
    });
  }

  ngOnInit(): void {
    this._prepareCountries();
    this._store.dispatch(fromCore.identifyUser());
    this._store.dispatch(fromCore.loadCurrencyRates());
    this._store.dispatch(fromCore.loadCurrenciesNames());
    this.user$ = this._store.select(fromCore.selectUser);
    this.returnUrl$ = this._store.select(fromCore.selectReturnUrl);
    this.subscription$ = this._store.select(fromCore.selectSubscription);
    this.paddingTop$ = this._store.select(fromCore.selectWebsitePaddingTop);
    this.openSignInModal$ = this._store.select(
      fromCore.selectIsOpenSignInModal
    );
    this.signInSubHeading$ = this._store.select(
      fromCore.selectSignInSubHeading
    );
    this._router.events
      .pipe(
        filter(events => events instanceof NavigationEnd),
        map(evt => this._route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe((x: any) => {
        this.layout = x?.layout;

        if (this.layout?.title && this.layout?.title !== 'MEtutors')
          this.translate.get(this.layout.title).subscribe(title =>
            this.translate.get('METUTORS_PLATFORM').subscribe(metutors => {
              if (this.layout?.description)
                this.translate
                  .get(this.layout.description)
                  .subscribe(description =>
                    this._store.dispatch(
                      fromCore.setMetaTags({
                        tags: {
                          title: this.layout?.title?.includes('_META_TITLE')
                            ? title
                            : `${title} - ${metutors}`,
                          description,
                          // keywords: '',
                          // url: ''
                        },
                      })
                    )
                  );
              else
                this._store.dispatch(
                  fromCore.setMetaTags({
                    tags: {
                      title: `${title} - ${metutors}`,
                      // description: '',
                      // keywords: '',
                      // url: ''
                    },
                  })
                );
            })
          );
        else
          this.translate
            .get('PERSONALIZED_ONLINE_TUTORING_META_TITLE')
            .subscribe(title =>
              this.translate
                .get('PERSONALIZED_ONLINE_TUTORING_META_DESC')
                .subscribe(description =>
                  this._store.dispatch(
                    fromCore.setMetaTags({
                      tags: {
                        title,
                        description,
                        // keywords: '',
                        // url: ''
                      },
                    })
                  )
                )
            );
      });
  }

  onCalculateHeight(padding: number): void {
    this._store.dispatch(
      fromCore.setWebsitePaddingTop({
        paddingTop:
          this.url.includes('/') &&
          this.url !== '/full' &&
          window.innerWidth > 1200
            ? 0
            : padding,
      })
    );
  }

  openSignInModal(returnUrl: string): void {
    this._store.dispatch(
      fromCore.openSignInModal({
        uid: '',
        returnUrl,
        affiliate: '',
        subHeading: 'GPT_MODAL',
      })
    );
  }

  closeSignInModal(): void {
    this._store.dispatch(fromCore.closeSignInModal());
  }

  ngOnDestroy(): void {
    this.routerSubscription$?.unsubscribe();
  }

  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.progressBar.start();
    }

    if (event instanceof NavigationEnd) {
      this.progressBar.complete();

      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
        this._document.body.scrollTop = 0;
        this._document.documentElement.scrollTop = 0;
      }
    }

    if (event instanceof NavigationCancel) {
      this.progressBar.complete();
    }

    if (event instanceof NavigationError) {
      this.progressBar.setConfig({ color: 'red' });
      this.progressBar.complete();
    }
  }

  private _checkLangauge(): void {
    const body = document.getElementsByTagName('body')[0];

    if (this.translate.currentLang === 'ar') {
      moment.locale('ar');
      body.setAttribute('dir', 'rtl');
    } else {
      moment.locale('en');
      body.setAttribute('dir', 'ltr');
    }
  }

  private _prepareCountries(): void {
    this._store.dispatch(fromCore.loadProgramCountries());
    this.countries$ = this._store.select(fromCore.selectProgramCountries);
    this.loadingCountries$ = this._store.select(
      fromCore.selectIsLoadingProgramCountries
    );
  }
}
