import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromCore from '@metutors/core/state';
import { LookupsService, UsersService } from '@services';
import * as lookupsActions from '../actions/lookups.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as languageActions from '../actions/language-menu.actions';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class LookupsEffects {
  loadUserTypes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(lookupsActions.loadUserTypes),
      withLatestFrom(this._store.select(fromCore.selectUserTypes)),
      mergeMap(([_, _userTypes]) => {
        if (!_userTypes || !_userTypes?.length) {
          return this._userService.getRoles().pipe(
            map(userTypes =>
              lookupsActions.loadUserTypesSuccess({
                userTypes,
              })
            ),
            catchError(error =>
              of(
                lookupsActions.loadUserTypesFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(lookupsActions.loadUserTypesEnded());
        }
      })
    )
  );

  changeCurrency$ = createEffect(() =>
    this._actions$.pipe(
      ofType(languageActions.changeCurrency),
      mergeMap(action =>
        this._userService.changeCurrency(action.currency).pipe(
          map(() => languageActions.changeCurrencySuccess()),
          catchError(error =>
            of(
              languageActions.changeCurrencyFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  changeLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(languageActions.changeLanguage),
      mergeMap(action =>
        this._userService.changeLanguage(action.language).pipe(
          map(() => languageActions.changeLanguageSuccess()),
          catchError(error =>
            of(
              languageActions.changeLanguageFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  loadProgramCountries$ = createEffect(() =>
    this._actions$.pipe(
      ofType(lookupsActions.loadProgramCountries),
      withLatestFrom(this._store.select(fromCore.selectProgramCountries)),
      mergeMap(([_, _countries]) => {
        if (!_countries || !_countries.length) {
          return this._lookupsService.getProgramCountries().pipe(
            map(countries =>
              lookupsActions.loadProgramCountriesSuccess({
                countries,
              })
            ),
            catchError(error =>
              of(
                lookupsActions.loadProgramCountriesFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(lookupsActions.loadProgramCountriesEnded());
        }
      })
    )
  );

  loadPrograms$ = createEffect(() =>
    this._actions$.pipe(
      ofType(lookupsActions.loadPrograms),
      withLatestFrom(this._store.select(fromCore.selectPrograms)),
      mergeMap(([_, _programs]) => {
        if (!_programs || !_programs.length) {
          return this._lookupsService.getPrograms().pipe(
            map(programs =>
              lookupsActions.loadProgramsSuccess({
                programs,
              })
            ),
            catchError(error =>
              of(
                lookupsActions.loadProgramsFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(lookupsActions.loadProgramsEnded());
        }
      })
    )
  );

  constructor(
    private _store: Store<any>,
    private _actions$: Actions,
    private _userService: UsersService,
    private _lookupsService: LookupsService
  ) {}
}
