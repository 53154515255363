import { orderBy } from 'lodash';
import { Store } from '@ngrx/store';
import { environment } from '@environment';
import * as fromCore from '@metutors/core/state';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, Subscription, withLatestFrom } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Affiliate, generalConstants, UserRole } from '@metutors/config';
import { ChooseCountryDialogComponent } from '@metutors/shared/components';
import {
  IUser,
  ICountry,
  IProgram,
  ISubscription,
} from '@metutors/core/models';
import {
  Input,
  OnInit,
  Output,
  ViewChild,
  Component,
  OnDestroy,
  ElementRef,
  EventEmitter,
  AfterViewChecked,
} from '@angular/core';
import {
  state,
  style,
  group,
  trigger,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'metutors-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(300, style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: '0' })),
        ]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(300, style({ height: '*' })),
          animate('400ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('navbar', { static: false }) navbar: ElementRef;
  @ViewChild('navbarSmall', { static: false }) navbarSmall: ElementRef;

  @Input() url: string;
  @Input() countries: ICountry[];
  @Input() loadingCountries: boolean;
  @Input() subscription: ISubscription;

  @Output() calculateHeight: EventEmitter<number> = new EventEmitter<number>();

  user$: Observable<IUser | null>;
  currencyRates$: Observable<any[]>;
  selectedCurrency$: Observable<any>;
  token$: Observable<string | undefined>;
  programs$: Observable<IProgram[] | null>;
  isCurrencyRatesLoading$: Observable<boolean>;
  openLanguageCurrencyModal$: Observable<boolean>;

  offsetHeight = 0;
  userRole = UserRole;
  selectedMenu: string;
  selectedLanguage: string;
  affiliate = Affiliate.gpt;
  clientUrl = environment.clientUrl;
  routerSubscription$: Subscription;

  constructor(
    private _dialog: MatDialog,
    private _store: Store<any>,
    private _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this._preparePrograms();
    this.selectedLanguage = this._translate.currentLang;

    this.token$ = this._store.select(fromCore.selectToken);
    this.user$ = this._store.select(fromCore.selectUser);

    this.currencyRates$ = this._store.select(fromCore.selectCurrencyRates).pipe(
      withLatestFrom(this._store.select(fromCore.selectCurrenciesNames)),
      map(([rates, currencies]) =>
        rates
          ? orderBy(
              Object.keys(rates).map(key => ({
                id: key,
                currency: currencies[key],
                name: `${currencies[key]} (${key})`,
              })),
              ['name', 'asc']
            )
          : []
      )
    );

    this.isCurrencyRatesLoading$ = this._store.select(
      fromCore.selectIsLoadingCurrencyRates
    );

    this.openLanguageCurrencyModal$ = this._store.select(
      fromCore.selectIsOpenLanguageCurrencyModal
    );

    this.selectedCurrency$ = this._store
      .select(fromCore.selectCurrentCurrency)
      .pipe(
        withLatestFrom(this._store.select(fromCore.selectCurrenciesNames)),
        map(([currencySymbol, currencies]) => ({
          id: currencySymbol,
          name: `${currencies[currencySymbol]} (${currencySymbol})`,
        }))
      );

    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  ngAfterViewChecked(): void {
    if (this.navbar) {
      if (this.offsetHeight !== this.navbar.nativeElement.offsetHeight) {
        this.offsetHeight = this.navbar.nativeElement.offsetHeight;

        this.calculateHeight.emit(this.offsetHeight);
      }
    } else {
      if (this.offsetHeight !== 0) {
        this.offsetHeight = 0;
        this.calculateHeight.emit(0);
      }
    }
  }

  onCurrencySelect(currency: any): void {
    this._store.dispatch(fromCore.changeCurrency({ currency: currency.id }));
  }

  onLanguageSelect(language: string): void {
    localStorage.setItem('DEFAULT_LANGUAGE', language);
    this._translate.use(language);
    this._store.dispatch(fromCore.changeLanguage({ language }));
  }

  onOpenLanguageCurrencyModal(): void {
    this._store.dispatch(fromCore.openLanguageCurrencyModal());
  }

  onCloseLanguageCurrencyModal(): void {
    this._store.dispatch(fromCore.closeLanguageCurrencyModal());
  }

  openSignInModal(returnUrl: string): void {
    this._store.dispatch(
      fromCore.openSignInModal({
        uid: '',
        returnUrl,
        affiliate: '',
        subHeading: 'GPT_MODAL',
      })
    );
  }

  onSelectProgram(id: number): void {
    if (id === generalConstants.nationalId) {
      const dialogRef = this._dialog.open(ChooseCountryDialogComponent, {
        width: '1000px',
        data: { countries: this.countries, isLoading: this.loadingCountries },
        panelClass: 'overflow-height',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          window.open(
            `${environment.clientUrl}/all-courses/${id.toString()}?grade=${
              result?.grade
            }&flag=${result.country?.flag}&image=${
              result.country?.image
            }&country=${result.country?.countryId}&description=${
              result.country?.description
            }`,
            '_blank'
          );
        }
      });
    } else {
      window.open(
        `${environment.clientUrl}/all-courses/${id.toString()}`,
        '_blank'
      );
    }
  }

  logout(): void {
    this._store.dispatch(fromCore.logout());
  }

  ngOnDestroy(): void {
    this.routerSubscription$?.unsubscribe();
  }

  private _preparePrograms(): void {
    this._store.dispatch(fromCore.loadPrograms());
    this.programs$ = this._store.select(fromCore.selectPrograms);
  }
}
